import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import TerapiaFloral from "./pages/TerapiaFloral";
import Registros from "./pages/Registros";
import Reiki from "./pages/Reiki";
import SobreMi from "./pages/SobreMi";
import MainLayout from "./layouts/MainLayout";

function App() {
  return (
    <Router>
      <div className="w-full mx-auto">
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="terapia-floral" element={<TerapiaFloral />} />
            <Route path="registros-akashicos" element={<Registros />} />
            <Route path="reiki" element={<Reiki />} />
            <Route path="sobre-mi" element={<SobreMi />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
