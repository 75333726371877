import React from "react";
import instagramIcon from "../assets/icons/instagram-icon.svg";
import facebookIcon from "../assets/icons/facebook-icon.svg";
import whatsappIcon from "../assets/icons/whatsapp-icon.svg";
import tiktokIcon from "../assets/icons/tiktok-icon.svg";
import youtubeIcon from "../assets/icons/youtube-icon.svg";

const SocialIcon = ({ icon, title, url }) => {
  return (
    <a
      className="bg-white p-1 rounded-lg"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <img className="w-4" src={icon} alt={title} />
    </a>
  );
};

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white text-center p-4">
      Los pies en la luna
      <div className="flex gap-4 justify-center pt-4">
        <SocialIcon
          icon={instagramIcon}
          title="instagram"
          url="https://www.instagram.com/lospiesen.laluna"
        />
        <SocialIcon
          icon={facebookIcon}
          title="Facebook"
          url="https://www.facebook.com/profile.php?id=100064031559416&locale=es_LA"
        />
        <SocialIcon
          icon={whatsappIcon}
          title="whatsApp"
          url="https://api.whatsapp.com/send/?phone=67390340&text&type=phone_number&app_absent=0"
        />
        <SocialIcon
          icon={tiktokIcon}
          title="TikTok"
          url="https://www.tiktok.com/@nadialospiesenlaluna"
        />
        <SocialIcon
          icon={youtubeIcon}
          title="Youtube"
          url="www.nadatodavia.com"
        />
      </div>
    </footer>
  );
};

export default Footer;
