import React from "react";
import Slider from "react-slick";
import section5Image from "../assets/images/fotoMia1.webp";
import fotoPerfil from "../assets/images/cajaGotero.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import product1 from "../assets/images/goteroAncho.webp";
import product2 from "../assets/images/masGoteros.webp";
import product3 from "../assets/images/gatoFlores.webp";
import product4 from "../assets/images/perroFlores.webp";
import product5 from "../assets/images/libroRegistrosFlores.webp";
import product6 from "../assets/images/registrosImagenLuz.webp";
import product7 from "../assets/images/cajaGotero.webp";
import product8 from "../assets/images/goteros.webp";
import moonIcon from "../assets/icons/moon-icon.svg";
import Section from "../components/Section";
import SectionReverse from "../components/SectionReverse";
import ListItem from "../components/ListItem";

const ProductItem = ({ image, name, price, url }) => (
  <a
    href={url}
    target="_blank"
    className="flex flex-col items-center p-4 text-primary"
    rel="noreferrer"
  >
    <img src={image} alt={name} className="w-32 h-32 object-cover" />
    <h3 className="mt-4 text-lg font-semibold">{name}</h3>
    <p className="mt-2 text-primary">${price}</p>
  </a>
);

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
  };

  return (
    <div className="">
      <Section
        title="¿Qué podes encontrar en Los pies en la luna?"
        imageUrl={fotoPerfil}
      >
        <p className="mb-6 font-medium text-primary">
          Te ayudo a volver a vos a traves de:
        </p>
        <ul className="mt-4 flex flex-col gap-2 text-primary">
          <ListItem
            icon={moonIcon}
            content="<b>Terapia Floral:</b> Utilizo esencias de flores para trabajar a nivel
                energético, abordando temas como ansiedad, miedos, autoestima y
                mucho más."
          />
          <ListItem
            icon={moonIcon}
            content="<b>Registros Akáshicos:</b> Una herramienta espiritual para acceder a
                la sabiduría del alma y obtener claridad en situaciones de vida."
          />
          <ListItem
            icon={moonIcon}
            content="<b>Reiki Usui y Kundalini:</b> Terapias energéticas que armonizan el
                cuerpo y la mente, promoviendo la sanación y el bienestar."
          />
          <ListItem
            icon={moonIcon}
            content="<b>Aromaterapia:</b> Utilizo aceites esenciales para equilibrar las
                emociones y mejorar la calidad de vida."
          />
        </ul>
        <p className="mt-4 flex flex-col gap-2 text-primary">
          Cada servicio está diseñado para ayudarte a descubrir tu ser auténtico
          y vivir con más paz y propósito.
        </p>
      </Section>
      <SectionReverse title="¿Quién soy?" imageUrl={section5Image}>
        <div className="flex flex-col gap-2">
          Soy Nadia, creadora de Los Pies en la Luna. <br />
          <br />
          Empecé mi camino de autoconocimiento a principios de 2019, después de
          trabajar varios años en una multinacional en el área de sistemas,
          donde me sentía muy angustiada y desconectada de mí misma. <br />
          <br />
          Una gran crisis me llevó a mirar hacia adentro y comenzar a meditar.
          Un día, pedí una señal para encontrar mi camino y, al día siguiente,
          recibí la notificación de que había ganado una beca para estudiar
          flores de Bach. Desde ese momento, me enamoré de las flores y se
          convirtieron en mi herramienta predilecta para el trabajo interior.
          <br />
          <br />A finales de 2019, comencé a dar sesiones de terapia floral y,
          con el tiempo, me capacité en otras herramientas, incluyendo registros
          akáshicos, Reiki Usui y Kundalini. También he estudiado aromaterapia,
          tarot y actualmente estoy en un instructorado de meditación y
          estudiando astrología.
          <br />
          <br />
          Te espero en mis sesiones para que puedas tener una conexión más
          profunda con vos.
        </div>
      </SectionReverse>
      <section className="bg-purple-200 p-4 md:p-6">
        <div className="container mx-auto grid gap-8">
          <h2 className="text-3xl mb-6 font-medium text-primary text-center">
            Algunos Testimonios
          </h2>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
            <div className="bg-secondary text-purple-200 rounded-xl p-4 flex flex-col gap-2 md:col-span-2 justify-between">
              <p className="italic font-medium text-xl">
                "No tengo palabras para describir la buena energia que transmite
                Nadia, todo con lo que trabaja hace que te sientas mejor, te
                deja un mensaje. Mis experiencias con los productos fueron
                excelentes desde el primer momento, con mi mascosta y luego sume
                a 2 perritos mas. Un dia probé hacerlo conmigo y fue muy
                placentera la sesion de reiki, por parte de los registros
                akáshicos fue muy movilizador. En estas etapas tuve la ayuda
                tambien de las flores de bach."
              </p>
              <span className="text-base text-primary font-semibold self-end">
                Catherine
              </span>
            </div>
            <div className="bg-secondary text-purple-200 rounded-xl p-4 flex flex-col gap-2 md:col-span-2 justify-between">
              <p className="italic font-medium text-xl">
                "Tomé varios goteros de flores en distintos momentos, trabajando
                miedos, inseguridades, bloqueos, dependiendo el estado emocional
                que me encontraba, en todos Nadia me pudo guiar para saber como
                trabajarlos y atravesarlos. Son procesos de fuertes cambios que
                si o si tenemos que estar dispuestos a enfrentarlos, las flores
                son grandes aperturas de información y claridad. Siempre me
                ayudaron a entenderme un poco más ✨ Gracias Nadia por la ayuda
                y guiarme en el proceso!"
              </p>
              <span className="text-base text-primary font-semibold self-end">
                Jazmin
              </span>
            </div>
            <div className="bg-secondary text-purple-200 rounded-xl p-4 flex flex-col gap-2 justify-between">
              <p className="italic font-medium text-xl">
                "Me encantan tus sesiones de flores y tus devoluciones en tarot
                🌸🌿✨"
              </p>
              <span className="text-base text-primary font-semibold self-end">
                Azul
              </span>
            </div>
            <div className="bg-secondary text-purple-200 rounded-xl p-4 flex flex-col gap-2 justify-between">
              <p className="italic font-medium text-xl">
                "Recomiendo!!!!! Gracias por acompañarme hace más de dos años a
                transitar todos mis procesos con las flores y con tus sesiones
                de Reiki ❤️🙌"
              </p>
              <span className="text-base text-primary font-semibold self-end">
                Daniela
              </span>
            </div>
            <div className="bg-secondary text-purple-200 rounded-xl p-4 flex flex-col gap-2 justify-between">
              <p className="italic font-medium text-xl">
                "100%recomendable, una genia como siempre!"
              </p>
              <span className="text-base text-primary font-semibold self-end">
                Cristian
              </span>
            </div>
            <div className="bg-secondary text-purple-200 rounded-xl p-4 flex flex-col gap-2 justify-between">
              <p className="italic font-medium text-xl">
                "Mi experiencia fue maravillosa, muy fuerte y motivadora, me
                ayudo con muchos aspectos de mi vida y entorno, pero
                principalmente a conocerme y descubrirme a mi misma y auto
                sanarme"
              </p>
              <span className="text-base text-primary font-semibold self-end">
                Jimena
              </span>
            </div>
          </div>
        </div>
      </section>
      <div className="container mx-auto py-8">
        <h2 className="text-3xl text-center mb-8 text-primary">
          Mis productos y servicios
        </h2>
        <Slider {...settings}>
          <ProductItem
            image={product1}
            name="Promo Terapia Floral - 2 Sesiones sin gotero"
            price="19.800"
            url="https://lospiesenlaluna.empretienda.com.ar/terapia-floral/pack-promocional-terapia-floral-2-sesiones-en-1-mes-sin-gotero"
          />
          <ProductItem
            image={product2}
            name="Promo Terapia Floral - 2 Sesiones con gotero"
            price="30.599"
            url="https://lospiesenlaluna.empretienda.com.ar/terapia-floral/pack-promocional-terapia-floral-2-sesiones-y-2-goteros-en-1-mes"
          />
          <ProductItem
            image={product3}
            name="Terpia floral sin gotero mascotas"
            price="9.999"
            url="https://lospiesenlaluna.empretienda.com.ar/terapia-floral/terpia-floral-sin-gotero-para-mascotas"
          />
          <ProductItem
            image={product4}
            name="Terpia floral con gotero mascotas"
            price="16.000"
            url="https://lospiesenlaluna.empretienda.com.ar/terapia-floral/terapia-floral-con-gotero-para-mascotas"
          />
          <ProductItem
            image={product5}
            name="Registros akáshicos + terapia floral sin gotero
"
            price="20.000"
            url="https://lospiesenlaluna.empretienda.com.ar/registros-akashicos/sesion-de-registros-akashicos-terapia-floral-sin-gotero"
          />
          <ProductItem
            image={product6}
            name="Registros akáshicos + terapia floral con gotero
"
            price="25.000"
            url="https://lospiesenlaluna.empretienda.com.ar/registros-akashicos/sesion-de-registros-akashicos-terapia-floral-con-gotero-incluido"
          />
          <ProductItem
            image={product7}
            name="Sesión de terapia floral sin gotero"
            price="11.000"
            url="https://lospiesenlaluna.empretienda.com.ar/terapia-floral/sesion-de-terapia-floral-sin-gotero"
          />
          <ProductItem
            image={product8}
            name="Sesión de terapia floral con gotero"
            price="17.000"
            url="https://lospiesenlaluna.empretienda.com.ar/terapia-floral/sesion-de-terapia-floral-con-gotero-incluido"
          />
        </Slider>
        <div className="text-center mt-12">
          <a
            target="_blank"
            href="https://lospiesenlaluna.empretienda.com.ar/"
            className="bg-purple-600 text-white py-2 px-6 rounded-xl font-medium hover:bg-purple-700 transition-colors"
            rel="noreferrer"
          >
            Ir a la tienda
          </a>
        </div>
      </div>
      <div>
        {/* Otras secciones del home */}

        <section className="bg-purple-200 p-4">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl mb-4 text-primary font-medium ">
              ¡Suscribite al Newsletter!
            </h2>
            <p className="mb-4 text-primary font-medium">
              Recibí promociones, actualizaciones sobre terapia floral, y más.
            </p>
            <form
              action="https://tu-endpoint-para-el-newsletter.com" // Cambia esto por tu backend o servicio de email
              method="POST"
              className="flex flex-col items-center"
            >
              <input
                type="text"
                name="name"
                placeholder="Tu nombre"
                className="p-2 mb-2 border border-gray-300 rounded"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Tu correo electrónico"
                className="p-2 mb-4 border border-gray-300 rounded"
                required
              />
              <button
                type="submit"
                className="bg-purple-800 text-white py-2 px-4 rounded font-medium"
              >
                Suscribirme
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
