import React from "react";
import section1Image from "../assets/images/registrosImagenLuz.webp";
import section2Image from "../assets/images/libroRegistrosFlores.webp";
import section5Image from "../assets/images/fotoMia1.webp";
import section3Image from "../assets/images/chakrasImagen.webp";
import section4Image from "../assets/images/escritorioSesion.webp";
import moonIcon from "../assets/icons/moon-icon.svg";
import starIcon from "../assets/icons/star-icon.svg";
import mariIcon from "../assets/icons/mariposa-icon.svg";
import penIcon from "../assets/icons/pen-icon.svg";

const ListItem = ({ text }) => {
  return (
    <li className="flex gap-2">
      <img className="w-4" src={moonIcon} alt={"luna"} />
      <p>{text}</p>
    </li>
  );
};
const ListItem2 = ({ text }) => {
  return (
    <li className="flex gap-2">
      <img className="w-4" src={starIcon} alt={"estrella"} />
      <p>{text}</p>
    </li>
  );
};

const ListItem3 = ({ text }) => {
  return (
    <li className="flex gap-2 text-primary pb-2">
      <img className="w-4" src={mariIcon} alt={"Mariposa"} />
      <div dangerouslySetInnerHTML={{ __html: text }} />
      {/* esto es para convertir el texto string en html para poner una parte en negrita */}
    </li>
  );
};
const ListItem4 = ({ text }) => {
  return (
    <li className="flex gap-2 text-primary pb-2">
      <img className="w-4" src={penIcon} alt={"circulo"} />
      <div dangerouslySetInnerHTML={{ __html: text }} />
      {/* esto es para convertir el texto string en html para poner una parte en negrita */}
    </li>
  );
};

const Registros = () => {
  return (
    <div>
      <section className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="place-self-center">
            <h2 className="text-3xl mb-6 font-medium text-primary">
              La sesión de registros akáshicos es para vos si…
            </h2>
            <ul className="flex flex-col gap-2 text-lg text-primary">
              <ListItem text="Buscás claridad sobre dónde estás parada en tu camino." />
              <ListItem text=" Necesitás orientación de tus guías espirituales." />
              <ListItem text="Querés conectar con tu alma a un nivel más profundo." />
              <ListItem text="Tenés la disposición para trabajar en tu desarrollo personal." />
            </ul>
          </div>
          <div>
            <img
              src={section1Image}
              alt="logo"
              className="h-screen w-full object-cover rounded-xl"
            />
          </div>
        </div>
      </section>
      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="place-self-center order-2 md:order-1">
            <img src={section2Image} alt="logo" className="rounded-xl" />
          </div>
          <div className="place-self-center order-1 md:order-2">
            <h2 className="text-3xl font-medium mb-6">
              En la sesión de registros akáshicos con terapia floral vas a
              conseguir…{" "}
            </h2>
            <ul className="flex flex-col gap-2 text-lg">
              <ListItem2 text="Mayor claridad sobre lo que te está pasando." />
              <ListItem2 text="Conectar más profundamente con tus guías espirituales." />
              <ListItem2 text="Recibir las palabras justas que necesitás escuchar en este momento." />
              <ListItem2 text="Información sobre en qué áreas trabajar en vos." />
              <ListItem2 text="Un mayor autoconocimiento." />
              <ListItem2 text=" Información sobre el estado de tus chakras y orientación sobre cómo alinearlos." />
            </ul>
            <h2 className="text-3xl font-medium m-6 my-4">
              En conjunto con la terapia floral:{" "}
              <ul className="flex flex-col gap-2 text-lg m-4">
                <ListItem2 text="Vas a tener una herramienta para trabajar en lo que surgió durante la sesión de registros." />
                <ListItem2 text="Aprenderás a gestionar tus emociones sin evadirlas." />
                <ListItem2 text="Aprenderás a aceptar tus procesos internos." />
                <ListItem2 text=" Aprenderás a escucharte cada vez más." />
                <ListItem2 text="Sentirte cada vez más en sintonía con vos." />
              </ul>
            </h2>
          </div>
        </div>
      </section>

      <section className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="place-self-center">
            <h2 className="text-3xl font-medium m-6 my-4 text-primary">
              ¿Qué son los registros akáshicos?
            </h2>

            <ListItem3
              text=" Los registros akáshicos son un espacio energético donde se guarda
              toda la información de tu alma, incluyendo tus experiencias
              pasadas, presentes y potenciales."
            />
            <ListItem3
              text="Durante una sesión, accedemos a
              esa información que estás lista para recibir y que te ayudará a
              comprender mejor tu camino en este momento de tu vida. "
            />

            <ListItem3
              text=" A través de la canalización, nos conectamos con tus guías
              espirituales, quienes nos transmiten los mensajes necesarios para
              que puedas alinearte más profundamente con tu esencia. "
            />
            <ListItem3
              text="Esta sesión
              no se enfoca en predecir el futuro ni en darte respuestas sobre tu
              vocación o trabajo, sino en brindarte una mayor comprensión de vos,
              ayudándote a conectar con tu auténtico ser. "
            />
            <ListItem3
              text=" Es un espacio
              donde podes descubrir cómo estar más en sintonía con tu alma y lo
              que realmente necesitas para avanzar en tu evolución personal."
            />

            <h2 className="text-3xl font-medium m-6 my-4 text-primary">
              Algunas consideraciones a tener en cuenta:
            </h2>

            <p className=" font-medium m-6 my-4 text-primary">
              {" "}
              Sé que muchas veces venimos a buscar respuestas cuando estamos en
              momentos de incertidumbre, y es totalmente normal. Pero es
              importante que sepas que:
            </p>

            <ListItem4
              text="<b>La decisión es tuya:</b> En la sesión vas a recibir información y
              guía, pero nadie te va a decir qué hacer ni va a decidir por vos.
              Tenés el libre albedrío para elegir lo que mejor resuene con vos.
              La idea es que te sientas más alineada con tu esencia y puedas
              tomar decisiones con más claridad y conciencia. "
            />

            <ListItem4
              text=" <b>No hago consultas sobre salud:</b> Las sesiones de registros akáshicos
              están orientadas al crecimiento personal y espiritual. No puedo ni
              debo ofrecer diagnósticos médicos ni consejos sobre temas de
              salud. Si tenés inquietudes de este tipo, es fundamental que
              consultes a un profesional de la salud. "
            />

            <ListItem4
              text=" <b>Confidencialidad:</b> Todo lo que charlemos en la sesión queda entre
              nosotros. Este es un espacio seguro para que puedas abrirte y
              recibir la guía que necesites."
            />

            <ListItem4
              text=" <b>El enfoque de la sesión:</b> La información que vas a recibir tiene
              que ver con ayudarte a entender mejor tu presente, no con predecir
              el futuro. El objetivo es que estés más alineada con tu alma y tu
              camino, para que puedas tomar decisiones desde un lugar más
              conectado con vos. "
            />

            <ListItem4
              text="<b>Actitud abierta y respetuosa:</b> Para aprovechar al máximo la sesión,
              es clave que vengas con la mente y el corazón abiertos. Este es un
              espacio de respeto mutuo, donde el foco está en tu bienestar y en
              ayudarte a evolucionar. "
            />
          </div>
          <div>
            <img src={section3Image} alt="logo" className="rounded-xl" />
          </div>
        </div>
      </section>
      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <img src={section4Image} alt="logo" className="rounded-xl" />
          </div>
          <div className="place-self-center order-1 md:order-2">
            <h2 className="text-3xl font-medium mb-6 text-center">
              Acerca de la sesión
            </h2>
            <ul className="flex flex-col gap-2 text-lg  mb-4">
              <li>
                Duración de sesión de registros akáshicos: Hasta 1 hora y media
                por WhatsApp (escrito y por audios).
              </li>
              <li>
                Pre-sesión: Entrego un PDF con información canalizada sobre el
                estado de tus chakras y una meditación previa para sintonizarte.
              </li>
              <li>
                Sesión de terapia floral: Hasta 60 minutos por Google Meet.
              </li>
              <li>
                Post-sesión: Entrega de un Journal para tomar mayor conciencia
                del proceso con las flores.
              </li>
              <li>
                Seguimiento: Siempre tendrás la posibilidad de contarme cómo te
                está yendo con el gotero después de la sesión.
              </li>
              <li>
                Tipos de sesión:
                <li>
                  Con gotero: Lo podés pasar a retirar por Banfield o recibirlo
                  por e-Pick (envío no incluido)
                </li>
                <li>
                  Sin gotero: Te paso la fórmula floral para que te lo prepares
                  en cualquier farmacia homeopática.
                </li>
              </li>
              <li>Los goteros se toman durante 15 días.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-purple-200 p-4 text-primary">
        <h2 className="text-3xl font-medium mb-6 text-center">
          Formas de pago:
        </h2>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          Se puede abonar por mercadopago o transferencia bancaria.
        </p>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          Se puede pagar la totalidad antes de la sesión o dejar una seña del
          50% (en caso de ser sesiones con gotero) de lo contrario se debe
          abonar la totalidad antes de la sesión.
        </p>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          En caso de cancelación de turno se requiere avisar con al menos 24
          horas de anticipación.
        </p>
      </section>
      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto place-self-center text-center">
          <h2 className="text-3xl font-medium mb-6">
            ¡Comenzá tu camino de autodescubrimiento!
          </h2>
          <p className="flex flex-col gap-2 text-lg mb-4">
            Elegí los registros akashicos junto a la terapia floral para volver
            a vos.
          </p>
          <a
            target="_blank"
            href="https://lospiesenlaluna.empretienda.com.ar/"
            className="bg-white text-purple-800 py-4 px-6 rounded-xl inline-block font-medium"
            rel="noreferrer"
          >
            ¡Quiero empezar!
          </a>
        </div>
      </section>
    </div>
  );
};

export default Registros;
