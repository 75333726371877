export default function Section({ title, children, imageUrl }) {
  return (
    <section className="bg-purple-200 px-4 py-8 md:px-6 md:py-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
        <div className="flex flex-col place-self-center">
          <h2 className="md:w-full w-4/5 text-center md:text-left place-self-center md:place-self-start text-3xl mb-6 font-medium text-primary">
            {title}
          </h2>
          <div className="md:text-lg">{children}</div>
        </div>
        <div>
          <img src={imageUrl} alt={title} className="rounded-xl" />
        </div>
      </div>
    </section>
  );
}
