import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    window.scroll(0, 0);
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-primary shadow-2xl p-4 flex text-lg flex-col items-end justify-end md:items-center md:justify-center fixed w-full z-10">
      <ul className="space-x-6 hidden md:flex">
        <li>
          <Link
            to="/"
            onClick={() => window.scroll(0, 0)}
            className="text-background hover:text-secondary"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/terapia-floral"
            onClick={() => window.scroll(0, 0)}
            className="text-background  hover:text-secondary"
          >
            Terapia Floral
          </Link>
        </li>
        <li>
          <Link
            to="/registros-akashicos"
            onClick={() => window.scroll(0, 0)}
            className="text-background hover:text-secondary"
          >
            Registros Akashicos
          </Link>
        </li>
        {/* <li>
          <Link to="/reiki" className="text-background hover:text-secondary">
            Reiki
          </Link>
        </li> */}
        <li>
          <Link
            to="/sobre-mi"
            onClick={() => window.scroll(0, 0)}
            className="text-background hover:text-secondary"
          >
            Mascotas
          </Link>
        </li>
        <li>
          <a
            target="_blank"
            href="https://lospiesenlaluna.empretienda.com.ar/"
            className="text-background hover:text-secondary"
            rel="noreferrer"
          >
            Tienda
          </a>
        </li>
      </ul>
      {/* Menú hamburguesa */}
      <div className="-mr-2 flex md:hidden">
        <button
          onClick={toggleMenu}
          className="inline-flex items-center justify-center p-2 rounded-md text-white"
        >
          <span className="mr-2 text-sm">Menú </span>
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      {/* Menú desplegable */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-right">
            <Link
              to="/"
              onClick={toggleMenu}
              className="block py-2 rounded-md text-base font-medium text-white"
            >
              Home
            </Link>
            <Link
              to="/terapia-floral"
              onClick={toggleMenu}
              className="block py-2 rounded-md text-base font-medium text-white"
            >
              Terapia floral
            </Link>
            <Link
              to="/registros-akashicos"
              onClick={toggleMenu}
              className="block py-2 rounded-md text-base font-medium text-white"
            >
              Registros Akashicos
            </Link>
            <Link
              to="/sobre-mi"
              onClick={toggleMenu}
              className="block py-2 rounded-md text-base font-medium text-white"
            >
              Mascotas
            </Link>
            <a
              target="_blank"
              href="https://lospiesenlaluna.empretienda.com.ar/"
              onClick={toggleMenu}
              className="block py-2 rounded-md text-base font-medium text-white"
              rel="noreferrer"
            >
              Tienda
            </a>
            {/* <Link
              to="/reiki"
              onClick={toggleMenu}
              className="block py-2 rounded-md text-base font-medium text-white"
            >
              Reiki
            </Link> */}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
