export default function SectionReverse({ title, children, imageUrl }) {
  return (
    <section className="bg-purple-800 px-4 py-8 md:px-6 md:py-12 text-white">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
        <div className="order-2 md:order-1">
          <img src={imageUrl} alt={title} className="rounded-xl" />
        </div>
        <div className="flex flex-col place-self-center order-1 md:order-2">
          <h2 className="md:w-full w-4/5 text-center md:text-left place-self-center md:place-self-start text-3xl mb-6 font-medium">
            {title}
          </h2>
          {children}
        </div>
      </div>
    </section>
  );
}
