import React from "react";
import section5Image from "../assets/images/manoCorazon.jpeg";
import hojaIcon from "../assets/icons/hoja-icon.svg";

const ListItem = ({ text, icon }) => {
  return (
    <li className="flex gap-2">
      <img className="w-4" src={hojaIcon} alt="icon" />
      <p>{text}</p>
    </li>
  );
};

const Reiki = () => {
  return (
    <div>
      <section className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="place-self-center">
            <h2 className="text-3xl mb-6 font-medium text-primary">
              ¿Qué es Reiki Usui?
            </h2>
            <ul className="flex flex-col gap-2 text-lg text-primary">
              <ListItem
                text="Reiki Usui es una técnica japonesa que canaliza y dirige la energía vital a través de símbolos sagrados, aplicados mediante la imposición de manos."
                icon={hojaIcon}
              />
              <ListItem
                text="Este método busca restablecer el equilibrio energético del cuerpo, armonizando los chakras y promoviendo la sanación integral."
                icon={hojaIcon}
              />
              <ListItem
                text="Su belleza reside en la capacidad de transmitir esta energía a distancia, trascendiendo las limitaciones físicas y temporales."
                icon={hojaIcon}
              />
            </ul>
          </div>
          <div>
            <img
              src={section5Image}
              alt="Reiki Usui"
              className="h-screen w-full object-cover rounded-xl"
            />
          </div>
        </div>
      </section>

      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <img
              src={section5Image}
              alt="Reiki Kundalini"
              className="rounded-xl"
            />
          </div>
          <div className="place-self-center order-1 md:order-2">
            <h2 className="text-3xl font-medium mb-6">
              ¿Qué es Reiki Kundalini?
            </h2>
            <ul className="flex flex-col gap-2 text-lg">
              <ListItem
                text="Reiki Kundalini es un enfoque que trabaja directamente con la energía kundalini, la cual reside en el chakra raíz."
                icon={hojaIcon}
              />
              <ListItem
                text="Esta energía asciende en espiral a través de los chakras, liberando bloqueos y promoviendo la expansión espiritual."
                icon={hojaIcon}
              />
              <ListItem
                text="A diferencia de Reiki Usui, este sistema no se apoya en símbolos, sino que utiliza la energía intrínseca de la Tierra para sanar y equilibrar."
                icon={hojaIcon}
              />
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="place-self-center">
            <h2 className="text-3xl font-medium mb-6 text-center text-primary">
              Conexión entre Reiki Usui y Kundalini
            </h2>
            <ul className="flex flex-col gap-2 text-lg text-primary">
              <ListItem
                text="Reiki Usui es una energía Yang que ingresa desde el chakra corona y desciende."
                icon={hojaIcon}
              />
              <ListItem
                text="Reiki Kundalini es una energía Yin que sube desde el chakra raíz."
                icon={hojaIcon}
              />
              <ListItem
                text="Juntos, estos sistemas complementan los flujos energéticos, equilibrando los polos energéticos (Yin y Yang) para una experiencia de sanación más completa."
                icon={hojaIcon}
              />
            </ul>
          </div>
          <div>
            <img
              src={section5Image}
              alt="Canalización de Chakras"
              className="rounded-xl"
            />
          </div>
        </div>
      </section>

      <section className="bg-purple-800 text-white p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <img
              src={section5Image}
              alt="Canalización de Chakras"
              className="rounded-xl"
            />
          </div>
          <div className="place-self-center order-1 md:order-2">
            <h2 className="text-3xl font-medium mb-6 text-center">
              Canalización de Chakras a través de Registros Akáshicos
            </h2>
            <ul className="flex flex-col gap-2 text-lg">
              <ListItem
                text="Incorporo la canalización de chakras mediante la apertura de registros akáshicos para obtener una claridad profunda sobre el estado de cada centro energético."
                icon={hojaIcon}
              />
              <ListItem
                text="Esta técnica proporciona información valiosa para movilizar la energía de cada chakra, identificando bloqueos específicos y facilitando su liberación."
                icon={hojaIcon}
              />
              <ListItem
                text="La canalización en formato PDF te ayuda a tomar conciencia de los aspectos que necesitas trabajar, potenciando los efectos de las sesiones de Reiki."
                icon={hojaIcon}
              />
            </ul>
          </div>
        </div>
      </section>
      <section id="integracion-reiki" className="bg-purple-200">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <h2 className="text-3xl font-medium mb-6 text-center text-primary">
            Cómo se Integra en una Sesión
          </h2>
          <p>
            Durante una sesión de Reiki, se combinan las energías de Reiki Usui
            y Kundalini con la canalización de chakras a través de los registros
            akáshicos para ofrecer una experiencia de sanación holística. Este
            enfoque integrado permite:
          </p>
          <ul>
            <li>
              Equilibrar y armonizar todos los chakras, asegurando un flujo
              energético fluido.
            </li>
            <li>
              Identificar y liberar bloqueos específicos que puedan estar
              afectando tu bienestar físico, emocional y espiritual.
            </li>
            <li>
              Proporcionar claridad y orientación sobre los aspectos de tu vida
              que necesitan atención y sanación.
            </li>
            <li>
              Facilitar un crecimiento personal y espiritual profundo, alineando
              tus energías internas con tu propósito de vida.
            </li>
          </ul>

          <h2 className="text-3xl font-medium mb-6 text-center text-primary">
            Beneficios de este Enfoque Integrado
          </h2>
          <ul>
            <li>
              <strong>Sanación Completa:</strong> Al combinar Reiki Usui,
              Kundalini y la canalización de chakras, se aborda el bienestar de
              manera integral.
            </li>
            <li>
              <strong>Claridad Energética:</strong> La canalización mediante los
              registros akáshicos proporciona una visión clara de tus centros
              energéticos.
            </li>
            <li>
              <strong>Equilibrio de Energías:</strong> La armonización de las
              energías Yin y Yang promueve un estado de equilibrio interno.
            </li>
            <li>
              <strong>Crecimiento Personal:</strong> Facilita una mayor
              comprensión de ti mismo y de tus necesidades energéticas y
              espirituales.
            </li>
          </ul>

          <h2 className="text-3xl font-medium mb-6 text-center text-primary">
            Próximos Pasos
          </h2>
          <p>
            Si estás listo/a para experimentar una sanación profunda y
            equilibrada, ¡comienza tu camino con nuestras sesiones de Reiki!
            Combina las poderosas energías de Reiki Usui y Kundalini con la
            claridad proporcionada por la canalización de chakras a través de
            los registros akáshicos para transformar tu vida de manera
            significativa.
          </p>
        </div>
      </section>
      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto place-self-center text-center">
          <h2 className="text-3xl font-medium mb-6">
            ¡Equilibra tu energía con Reiki!
          </h2>
          <p className="flex flex-col gap-2 text-lg mb-4">
            Elige entre Reiki Usui, Reiki Kundalini o una combinación de ambos
            para un equilibrio completo de tu energía.
          </p>
          <a
            href="#"
            className="bg-white text-purple-800 py-4 px-6 rounded-xl inline-block font-medium"
            // Cambia el href al enlace del producto cuando esté disponible
          >
            Quiero empezar!
          </a>
        </div>
      </section>
    </div>
  );
};
export default Reiki;
